import React, {useEffect, useReducer} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import "Common/Module/FontAwesome";
import Login from "./Account/Login";
import Home from "./Home/Home";
import PageCardUploader from "./Upload/PageCardUploader";
import NotFound from "./Error/NotFound";
import {initialState, UserStateReducer} from "./Common/Context/UserStateReducer";
import {UserStateContext} from "./Common/Context/UserStateContext";
import {ServerApi} from "./Common/Module/ServerApi";
import Logout from "./Account/Logout";
import ArchiveKks from "CardArchive/KoikatuSunshine/ArchiveKks";
import CreateNewAccount from "./Account/CreateNewAccount";
import CreateAccountSucceed from "./Account/CreateAccountSucceed";
import LoggedIn from "./Account/LoggedIn";
import PageCardsKks from "./Card/KoikatuSunshine/PageCardsKks";
import PageCardsHc from "./Card/HoneyCome/PageCardsHc";
import PageMypageCardList from "./Mypage/PageMypageCardList";
import PageMypageCardEdit from "./Mypage/PageMypageCardEdit";
import {PageCardDetailKks} from "./Card/KoikatuSunshine/PageCardDetailKks";
import {PageCardDetailHc} from "./Card/HoneyCome/PageCardDetailHc";

function App() {
	const [state, dispatch] = useReducer(UserStateReducer, initialState);

	useEffect(() => {
		if (!state.initialized) {
			ServerApi.get("/api/account/status", {}).then((data) => {
				if (data.logged_in) {
					dispatch({action: "set", user_id: data.user_id, display_name: data.display_name});
				} else {
					dispatch({action: "destroy"});
				}
			});
		}
	});

	return (
		<UserStateContext.Provider value={{state, dispatch}}>
			<div className="App">
				<BrowserRouter>
					<Routes>
						<Route path={`/`} element={<Home/>}/>
						<Route path={`/account/register`} element={<CreateNewAccount/>}/>
						<Route path={`/account/created`} element={<CreateAccountSucceed/>}/>
						<Route path={`/account/login`} element={<Login/>}/>
						<Route path={`/account/logged_in`} element={<LoggedIn/>}/>
						<Route path={`/account/logout`} element={<Logout/>}/>
						<Route path={`/upload`} element={<PageCardUploader/>}/>
						<Route path={`/mypage`} element={<PageMypageCardList/>}/>
						<Route path={`/mypage/card/:own_id`} element={<PageMypageCardEdit/>}/>
						<Route path={`/cards/kks`} element={<PageCardsKks/>}/>
						<Route path={`/cards/hc`} element={<PageCardsHc/>}/>
						<Route path={`/card/kks/:card_id`} element={<PageCardDetailKks/>}/>
						<Route path={`/card/hc/:card_id`} element={<PageCardDetailHc/>}/>
						<Route path={`/archive/kks`} element={<ArchiveKks/>}/>
						<Route path={`*`} element={<NotFound/>}/>
					</Routes>
				</BrowserRouter>
			</div>
		</UserStateContext.Provider>
	);
}

export default App;
