import styled from "styled-components";

export const CloseButton = styled.button`
	display: block;
	position: absolute;
	top: 3px;
	right: 3px;
	width: 30px;
	height: 30px;
	border: 2px solid #888;
	background: #fff;
	opacity: 70%;

	&:before, &:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 3px;
		height: 27px;
		background: #333;
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
`;

export default CloseButton;
