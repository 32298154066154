import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {CSSProperties} from "react";
import {ImageEndpoint} from "../Module/ImageEndpoint";

type typeProps = {
	url: string,
	visible: boolean,
	style: CSSProperties,
}

/**
 * ダウンロードアイコン
 * @constructor
 */
export const DownloadIcon = (props: typeProps) => {
	const styleIcon: CSSProperties = {
		fontSize: "2em",
		backgroundColor: "#fff",
		padding: 3,
		opacity: "80%",
		borderRadius: "15%",
		...props.style,
	}

	return (
		<a href={ImageEndpoint.url(props.url)} style={{visibility: props.visible ? "visible" : "hidden"}}>
			<FontAwesomeIcon style={styleIcon} icon={["fas", "download"]} />
		</a>
	);
}
