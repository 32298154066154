import React, {CSSProperties} from 'react';
import {ImageEndpoint} from "../Common/Module/ImageEndpoint";
import {typeCardItemMypage} from "./TypeMypage";
import {Link} from "react-router-dom";

type typeProps = {
	item: typeCardItemMypage,
}

const styleImage: CSSProperties = {
	width: 126,
	height: 176,
	margin: "auto",
	cursor: "pointer",
};

const styleFrame: CSSProperties = {
	position: "relative",
	float: "left",
	margin: "3px",
	padding: 4,
	backgroundColor: "#ccc",
	borderRadius: "5px",
}

const styleProduct: CSSProperties = {
	borderRadius: "5px",
	padding: "0px 5px",
	fontSize: "1.0em",
	cursor: "help",
}

const styleCaption: CSSProperties = {
	backgroundColor: "#eee",
	padding: "2px",
}

const styleStatus: CSSProperties = {
	fontSize: "1.0em",
	fontWeight: "bold",
	padding: "0px 4px",
	marginLeft: "8px",
	borderRadius: "5px",
	border: "solid 1px #ccc",
	cursor: "help",
}

const stylePublic: CSSProperties = {
	...styleStatus,
	color: "#333",
	backgroundColor: "#bf4",
}

const stylePrivate: CSSProperties = {
	...styleStatus,
	color: "#fff",
	backgroundColor: "#44a",
}

/**
 * @param props
 * @constructor
 */
export const CardItemMypage = (props: typeProps) => {
	const item: typeCardItemMypage = props.item;
	const name: string = "[" + item.product.id + "] (" + item.personality_name + ") " + item.name;

	return (
		<div style={styleFrame}>
			<div style={styleCaption}>
				<span style={styleProduct} className={"product-" + item.product.id}
					  title={item.product.name}>{item.product.name_short}</span>
				<span style={item.is_public ? stylePublic : stylePrivate}>{item.is_public ? "公開" : "非公開"}</span>
			</div>
			<div>
				<Link to={"/mypage/card/" + item.own_id}>
					<img alt={name} title={name} style={styleImage} src={ImageEndpoint.url(item.images.main_thumb)}/>
				</Link>
			</div>
		</div>
	);
}
