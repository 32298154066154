import React from 'react';

function NotFound() {
    return (
        <div>
            404 Not Found
        </div>
    );
}

export default NotFound;
