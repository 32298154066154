import Accordion from 'react-bootstrap/Accordion';
import {Button} from "react-bootstrap";
import {useState} from "react";

const style = {
	backgroundColor: "#eee",
};

const styleButton = {
	fontSize: "0.8em",
	padding: "3px",
	margin: "2px",
};

type typePersonalityItem = [number, string, string];

const constPersonalities: typePersonalityItem[] = [
	[-2, "指定なし", "All"],
	[-1, "男", "Male"],
	[0, "明るい", "Cheerful"],
	[1, "クール", "Cool"],
	[2, "母性", "MotherFigure"],
	[3, "気弱", "ScaredyCat"],
	[4, "ボーイッシュ", "Boyish"],
	[5, "無邪気", "Innocence"],
];

type typePageProps = {
	onChanged: (sex: string, personality: string) => void,
};

export const SearchMenuKksArchive = (props: typePageProps) => {
	const [selectedPersonality, setSelectedPersonality] = useState(-2);

	const onClickPersonality = async (id: number) => {
		setSelectedPersonality(id);
		const sex = (id === -2) ? "" : (id === -1 ? "0" : "1");
		const personality = (id === -2 || id === -1) ? "" : id.toString();
		props.onChanged(sex, personality);
	};

	return (
		<Accordion>
			<Accordion.Item eventKey="0">
				<Accordion.Header>検索条件</Accordion.Header>
				<Accordion.Body>
					{
						constPersonalities.map((item) => {
							const id = item[0];
							const name = item[1];
							return (
								<Button key={id} variant={id === selectedPersonality ? "primary" : "secondary"} style={styleButton} onClick={() => {
									onClickPersonality(id)
								}}>{name}</Button>
							);
						})
					}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}

export default SearchMenuKksArchive;
