import React, {CSSProperties} from "react";

export type typeInputErrors = Array<string>;

type typePropsValidationTip = {
	styleError?: CSSProperties,
	errorStatus: typeInputErrors,
	errorId: string,
}

const styleDefaultError: CSSProperties = {
	color: "#f00",
}

export const ValidationTip = function (props: React.ComponentProps<"span"> & typePropsValidationTip) {
	const styleError = props.styleError ?? styleDefaultError;
	const style: CSSProperties | undefined = props.errorStatus.includes(props.errorId) ? styleError : undefined;
	return (
		<span style={style}>
			{props.children}
		</span>
	);
}

type typeValidationItem = {
	lengthMin?: number,
	lengthMax?: number,
	format?: string,
	required?: boolean,
}

type typeValidations = {
	[key: string]: typeValidationItem
}

const validationMethods = {
	lengthMin: (value: string, params: any, checkRequired: boolean): boolean => {
		const length: number = params;
		if (!checkRequired && value.length === 0) {
			return true;
		}
		return value.length >= length;
	},
	lengthMax: (value: string, params: any, checkRequired: boolean): boolean => {
		const length: number = params;
		if (!checkRequired && value.length === 0) {
			return true;
		}
		return value.length <= length;
	},
	format: (value: string, params: any, checkRequired: boolean): boolean => {
		const format: string = params;
		if (!checkRequired && value.length === 0) {
			return true;
		}
		return !!value.match(format);
	},
	required: (value: string, params: any, checkRequired: boolean): boolean => {
		const required: boolean = params;
		if (!checkRequired || !required) {
			return true;
		}
		return value.length > 0;
	}
};

export class InputValidator {
	validations: typeValidations;

	/**
	 * コンストラクタ
	 */
	constructor(validations: typeValidations) {
		this.validations = validations;
	}

	/**
	 * 検証
	 * @param targetId
	 * @param value
	 * @param checkRequired
	 */
	validate(targetId: string, value: string, checkRequired: boolean): typeInputErrors {
		const items: typeValidationItem = this.validations[targetId];
		const errors = [] as typeInputErrors;
		Object.keys(items).forEach((key: string) => {
			const params: any = items[key as keyof typeValidationItem];
			const method = validationMethods[key as keyof typeValidationItem];
			if (!method(value, params, checkRequired)) {
				errors.push(key);
			}
		});
		return errors;
	}
}
