import React, {ReactNode} from 'react';
import GlobalHeader from "../Section/GlobalHeader";

type Props = {
    children: ReactNode
}

function BasicLayout(props: Props) {
    return (
        <>
            <GlobalHeader />
            <div>{props.children}</div>
        </>
    );
}

export default BasicLayout;
