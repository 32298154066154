import {Container, Pagination} from "react-bootstrap";
import React, {CSSProperties} from "react";

export type TypePaginationState = {
	page: number,
	page_size: number,
	total: number | null,
};

type TypeProps = {
	state: TypePaginationState,
	onClick: (i: number) => void,
};

const style: CSSProperties = {
	margin: "4px 8px",
};

const styleItem: CSSProperties = {
	minWidth: "50px",
}

const stylePageStatus: CSSProperties = {
	display: "list-item",
	fontSize: "0.8rem",
	padding: "0.75em 0.375em",
};

export const CustomPagination = function (props: TypeProps) {
	const page: number = props.state.page;
	const page_size: number = props.state.page_size;
	const total: number | null = props.state.total;
	const num_pages = total === null ? 0 : Math.ceil(total / page_size);
	const range: number = 5;
	const start = page < range ? 0 : page - range;
	const end: number = start + range * 2 + 1;

	let items = [{
		key: "first",
		page: 0,
		display: "<<",
		active: page === 0,
		disabled: page === 0,
	}, {
		key: "prev",
		page: page - 1,
		display: "<",
		active: false,
		disabled: page === 0,
	}];

	for (let i: number = start; i < end; i++) {
		if (i >= num_pages) {
			break;
		}
		items.push({
			key: i.toString(),
			page: i,
			display: (i + 1).toString(),
			active: page === i,
			disabled: false,
		});
	}

	items.push({
		key: "next",
		page: page + 1,
		display: ">",
		active: false,
		disabled: page >= num_pages - 1,
	});

	items.push({
		key: "last",
		page: num_pages - 1,
		display: ">>",
		active: page === num_pages - 1,
		disabled: page === num_pages - 1,
	});

	return (
		<Container>
			<Pagination style={style}>
				{
					items.map((item) => {
						return (
							<Pagination.Item key={item.key} style={styleItem} disabled={item.disabled} active={item.active} onClick={item.disabled ? undefined : () => {
								props.onClick(item.page);
							}}>
								{item.display}
							</Pagination.Item>
						);
					})
				}
				<div style={stylePageStatus}>
					Page: {page + 1} / {num_pages} Total: {total}
				</div>
			</Pagination>
		</Container>
	);
}
