import React, {useEffect, useState} from 'react';
import SearchMenuArchiveKks from "./SearchMenuArchiveKks";
import BasicLayout from "../../Common/Layout/BasicLayout";
import {ServerApi} from "../../Common/Module/ServerApi";
import ArchiveKksCardItem from "./ArchiveKksCardItem";
import {CustomPagination} from "../../Common/Part/CustomPagination";
import {typeArchiveCardItem} from "./TypeArchiveKksCardItem";

function ArchiveKks() {
	const pageSize = 30;

	const [cardList, setCardList] = useState([])

	const [searchConditions, setSearchConditions] = useState({
		page: 0,
		sex: "",
		personality: "",
	});

	const [paginationState, setPaginationState] = useState({
		page: 0,
		page_size: pageSize,
		total: null,
	});

	const listItems = async (page: number, sex: string, personality: string) => {
		const response = await ServerApi.get("/api/archive/kks/search", {
			page: page.toString(),
			page_size: pageSize.toString(),
			sex: sex,
			personality: personality,
		});
		setSearchConditions({
			page: page,
			sex: sex,
			personality: personality,
		});
		setCardList(response.list);
		setPaginationState(response.pagination);
	}

	useEffect(() => {
		 listItems(0, "", "").then();
	}, []);

	const onPageChanged = async (page: number) => {
		searchConditions.page = page;
		listItems(page, searchConditions.sex, searchConditions.personality).then();
	};

	const onConditionChanged = (sex: string, personality: string) => {
		listItems(0, sex, personality).then();
	};

	return (
		<BasicLayout>
			<SearchMenuArchiveKks onChanged={onConditionChanged} />
			<CustomPagination state={paginationState} onClick={onPageChanged} />

			<div style={{backgroundColor: "#eef"}}>
				{
					cardList.map((card: typeArchiveCardItem) => {
						return <ArchiveKksCardItem key={card.file_id} item={card} />
					})
				}
			</div>
		</BasicLayout>
	);
}

export default ArchiveKks;
