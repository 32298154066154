export type UserState = {
	initialized: boolean,
	logged_in: boolean,
	user_id: number | null,
	display_name: string | null,
};

export type UserStateAction = {
	action: 'set'
	user_id: number,
	display_name: string | null,
} | {
	action: 'destroy'
};

export const UserStateReducer = (state: UserState, action: UserStateAction): UserState => {
	switch (action.action) {
		case 'set':
			return {
				...state,
				initialized: true,
				logged_in: true,
				user_id: action.user_id,
				display_name: action.display_name
			};
		case 'destroy':
			return {
				...state,
				initialized: true,
				logged_in: false,
				user_id: null,
				display_name: null
			};
		default:
			return state;
	}
}

export const initialState: UserState = {
	initialized: false,
	logged_in: false,
	user_id: null,
	display_name: null,
};
