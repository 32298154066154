import React, {useEffect, useState} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {ServerApi} from "../Common/Module/ServerApi";
import {CustomPagination} from "../Common/Part/CustomPagination";
import {CardItemMypage} from "./CardItemMypage";
import {typeCardItemMypage} from "./TypeMypage";

/**
 * カード検索
 * @constructor
 */
function PageMypageCardList() {
	const pageSize = 30;

	const [cardList, setCardList] = useState([])

	const [searchConditions, setSearchConditions] = useState({
		page: 0,
		product_id: "",
		status: "",
	});

	const [paginationState, setPaginationState] = useState({
		page: 0,
		page_size: pageSize,
		total: null,
	});

	const listItems = async (page: number, status: string, product_id: string) => {
		const response = await ServerApi.get("/api/mypage/card/list", {
			page: page.toString(),
			page_size: pageSize.toString(),
			status: status,
			product_id: product_id,
		});
		setSearchConditions({
			page: page,
			status: status,
			product_id: product_id,
		});
		setCardList(response.list);
		setPaginationState(response.pagination);
	}

	useEffect(() => {
		listItems(0, "", "").then();
	}, []);

	const onPageChanged = async (page: number) => {
		searchConditions.page = page;
		listItems(page, searchConditions.status, searchConditions.product_id).then();
	};

	return (
		<BasicLayout>
			<CustomPagination state={paginationState} onClick={onPageChanged} />
			<div style={{backgroundColor: "#eef"}}>
				{
					cardList.map((card: typeCardItemMypage) => {
						return <CardItemMypage key={card.own_id} item={card} />
					})
				}
			</div>
		</BasicLayout>
	);
}

export default PageMypageCardList;
