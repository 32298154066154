import axios from "axios"

type QueryParams = Record<string, string>;

type UploadFiles = { [key: string]: File };

export const ServerApi = {
	get: async function (url: string, params: QueryParams = {}) {
		const query_string: URLSearchParams = new URLSearchParams(params);
		const request_url = process.env.REACT_APP_API_ENDPOINT + url + "?" + query_string.toString();
		const response = await axios.get(request_url, {withCredentials: true});
		return response.data;
	},

	post: async function (url: string, data: object) {
		const request_url = process.env.REACT_APP_API_ENDPOINT + url;
		const response = await axios.post(request_url, data, {withCredentials: true});
		return response.data;
	},

	upload: async function (url: string, params: QueryParams = {}, files: UploadFiles) {
		const post_data: FormData = new FormData();
		Object.keys(params).forEach(function (key: string) {
			post_data.set(key, params[key]);
		});

		Object.keys(files).forEach(function (key: string) {
			post_data.append(key, files[key]);
		});

		const request_url = process.env.REACT_APP_API_ENDPOINT + url;
		const response = await axios.post(request_url, post_data, {
			headers: {
				"Content-Type": 'multipart/form-data',
			},
			withCredentials: true
		});
		return response.data;
	},
}
