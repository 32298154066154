import React, {CSSProperties} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";

const styleRegisterNav:CSSProperties = {
	fontSize: "0.8em",
	color: "#888",
}

function CreateNewAccount() {
	return (
		<BasicLayout>
			<Container className="mt-3">
				<h3>ユーザー登録完了</h3>
			</Container>
			<Container style={styleRegisterNav} className="mt-2 mb-2">
				さっそくログインしてみましょう ⇒ <Link className="link-primary" to={"/account/login?created=1"}>ログイン</Link>
			</Container>
		</BasicLayout>
	);
}

export default CreateNewAccount;
