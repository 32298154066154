import {library} from '@fortawesome/fontawesome-svg-core';
import {
	faSun,
	faHeart,
	faClover,
	faStar,
	faMoon,
	faSeedling,
	faMugSaucer,
	faFire,
	faDownload,
} from '@fortawesome/free-solid-svg-icons';

import {
	faSoccerBall,
	faLightbulb,
	faCopy,
} from '@fortawesome/free-regular-svg-icons';

library.add(
	faSun,
	faHeart,
	faClover,
	faStar,
	faMoon,
	faSeedling,
	faMugSaucer,
	faFire,
	faDownload,

	faSoccerBall,
	faLightbulb,
	faCopy,
);
