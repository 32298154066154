import React, {useState} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {Button, Container} from "react-bootstrap";
import {UserStateContext} from "../Common/Context/UserStateContext";
import {useNavigate} from "react-router";
import {ServerApi} from "../Common/Module/ServerApi";

function Logout() {
	const {state, dispatch} = React.useContext(UserStateContext);
	const [flagInProgress, setFlagInProgress] = useState<boolean>(false);
	const navigate = useNavigate();

	const onClickLogout = () => {
		ServerApi.post("/api/account/logout", {}).then((data) => {
			setFlagInProgress(false);
			dispatch({action: "destroy"});
			navigate("/account/login?logout=1");
		}).catch(function (result) {
			const data = result.response.data;
			setFlagInProgress(false);
		});

	}

	return (
		<BasicLayout>
			<Container className="mt-3">
				<h3>ログアウトしますか？</h3>
			</Container>
			<Container className="mt-2 mb-2">
				<Button variant="primary" onClick={onClickLogout} disabled={flagInProgress}>ログアウト</Button>
			</Container>
		</BasicLayout>
	);
}

export default Logout;
