import React, {CSSProperties, useEffect, useState} from "react";
import BasicLayout from "../../Common/Layout/BasicLayout";
import {ServerApi} from "../../Common/Module/ServerApi";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {ImageEndpoint} from "../../Common/Module/ImageEndpoint";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {typeCardDetailCommon} from "../Common/TypeCardSearch";

const styleImage: CSSProperties = {
	cursor: "pointer",
	margin: "auto",
}

const paddingImage = 6;
const styleImageFrame: CSSProperties = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: 252 + paddingImage * 2,
	height: 352 + paddingImage * 2,
	padding: paddingImage,
	margin: 8,
	backgroundColor: "#ddd",
	borderRadius: "6px",
}

const styleShareLink: CSSProperties = {
	cursor: "pointer",
}

const styleCopyIcon: CSSProperties = {
	marginLeft: 8,
	cursor: "pointer",
}

const styleCopyMessage: CSSProperties = {
	fontSize: "0.9em",
	color: "#00f",
}

export const PageCardDetailKks = () => {
	const {card_id} = useParams<string>();
	const [card, setCard] = useState<typeCardDetailCommon | null>(null);
	const [flagFlip, setFlagFlip] = useState<boolean>(false);
	const [shareUrl, setShareUrl] = useState<string | null>(null);
	const [messageCopied, setMessageCopied] = useState<string>("");

	const flipMainImage = () => {
		setFlagFlip(false);
	}

	const flipSubImage = () => {
		setFlagFlip(true);
	}

	useEffect(() => {
		ServerApi.get("/api/card/kks/get", {
			card_id: (card_id ?? "").toString(),
		}).then((response) => {
			const data: typeCardDetailCommon = response as typeCardDetailCommon;
			setCard(data);
			setShareUrl(ImageEndpoint.share_url(data.product.url_id, data.card_id));
		});
	}, [card_id]);

	if (card === null) {
		return (
			<BasicLayout>
				<Spinner style={{width: 50, height: 50, margin: 50, opacity: "50%"}}/>
			</BasicLayout>
		);
	}

	const copyShareLinkToClipboard = async () => {
		if (shareUrl !== null && navigator.clipboard) {
			setMessageCopied("クリップボードにコピーしました");
			await navigator.clipboard.writeText(shareUrl);
			setTimeout(function () {
				setMessageCopied("");
			}, 2000);
		}
	}

	return (
		<BasicLayout>
			<Container style={{width: 800}} className="mb-5">
				<Row>
					<Col style={{backgroundColor: "#fff"}}>
						<div style={styleImageFrame}>
							{flagFlip ?
								<img style={styleImage} src={ImageEndpoint.url(card.images.sub)} alt={"サブ画像"}
									 onClick={flipMainImage}/> :
								<img style={styleImage} src={ImageEndpoint.url(card.images.main)} alt={"メイン画像"}
									 onClick={flipSubImage}/>}
						</div>
						<div className="clearfix"/>
					</Col>
					<Col style={{backgroundColor: "#fff", textAlign: "left"}}>
						<dl>
							<dt>ファイルフォーマット</dt>
							<dd>{card.product.name}</dd>
						</dl>
						<dl>
							<dt>キャラクター名</dt>
							<dd>{card.card_params.name}</dd>
						</dl>
						<dl>
							<dt>性格</dt>
							<dd>{card.card_params.personality_name}</dd>
						</dl>
						<dl>
							<dt>アップロードユーザー名</dt>
							<dd>{card.user_name}</dd>
						</dl>
						<dl>
							<dt>ダウンロード</dt>
							<dd>
								<a href={ImageEndpoint.url(card.download_url)}>Download</a>
							</dd>
						</dl>
						<dl>
							<dt>シェア用リンク</dt>
							<dd>
								<a className="primary" style={styleShareLink} onClick={copyShareLinkToClipboard} title="クリックしてクリップボードへコピー">{shareUrl}</a>
								<FontAwesomeIcon style={styleCopyIcon} icon={["far", "copy"]} onClick={copyShareLinkToClipboard} title="クリックしてクリップボードへコピー"/>
							</dd>
							<span style={styleCopyMessage}>{messageCopied}</span>
						</dl>
					</Col>
				</Row>
				<Row>
					<Col style={{backgroundColor: "#eee", textAlign: "left", borderRadius: "5px", padding: "6px 12px"}}>
						<dl>
							<dt>カード説明</dt>
							<dd><p>{card.description}</p></dd>
						</dl>
						<dl>
							<dt>タグ</dt>
							<dd><p>{card.tags}</p></dd>
						</dl>
					</Col>
				</Row>
			</Container>
		</BasicLayout>
	);
}
