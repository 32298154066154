import React, {CSSProperties, useState} from 'react';
import {ImageEndpoint} from "../../Common/Module/ImageEndpoint";
import {DownloadIcon} from "../../Common/Part/DownloadIcon";
import {typeCardItemCommon} from "../Common/TypeCardSearch";
import {Link} from "react-router-dom";

type TypeProps = {
	item: typeCardItemCommon,
}

const styleImage: CSSProperties = {
	width: 126,
	height: 176,
	margin: "auto",
};

const styleFrame: CSSProperties = {
	position: "relative",
	float: "left",
	margin: "3px",
	padding: 3,
	backgroundColor: "#ccc",
	cursor: "pointer",
}

const styleDownloadIcon: CSSProperties = {
	display: "block",
	position: "absolute",
	right: 5,
	bottom: 5,
}

/**
 * @param props
 * @constructor
 */
export const CardItemHc = (props: TypeProps) => {
	const [flagMouseOver, setFlagMouseOver] = useState<boolean>(false);
	const item: typeCardItemCommon = props.item;
	const name: string = "(" + item.personality_name + ") " + item.name;

	const onMouseOver = () => {
		setFlagMouseOver(true);
	};

	const onMouseLeave = () => {
		setFlagMouseOver(false);
	}

	return (
		<div style={styleFrame} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
			<Link to={"/card/" + item.product.url_id + "/" + item.card_id}>
				<img alt={name} title={name} style={styleImage} src={ImageEndpoint.url(item.images.main_thumb)}/>
			</Link>
			<DownloadIcon style={styleDownloadIcon} url={item.download_url} visible={flagMouseOver}/>
		</div>
	);
}
