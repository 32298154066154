import React, {CSSProperties} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";

const styleHome: CSSProperties = {
	textAlign: "left",
	width: 800,
	marginBottom: 100,
}

const styleDisabled: CSSProperties = {
	color: "#aaa",
}

function Home() {
	return (
		<BasicLayout>
			<Container className="mt-3" style={styleHome}>
				<Container>
					<h4>ILLUSION キャラクターカードアップローダー</h4>
					<p>
						このアップローダーは、以下のファイルフォーマットに対応しています。
						<ul>
							<li>ハニカム</li>
							<li>コイカツ！サンシャイン</li>
						</ul>
						以下のフォーマットは後日対応予定です。
						<ul>
							<li>ルームガール</li>
							<li>コイカツ！</li>
							<li>AI少女/ハニーセレクト</li>
						</ul>
					</p>
					<h5>ユーザー登録</h5>
					<p>
						キャラクターカードをアップロードするには<Link className="link-primary" to={"/account/register"}>ユーザー登録</Link>が必要です。<br/>
						登録にあたりメールアドレスは不要ですが、パスワードを忘れるとアカウントの復旧ができなくなりますのでご注意ください。
					</p>
					<h5>ダウンロード</h5>
					<p>
						ダウンロードにはユーザー登録は必要ありません。上部メニューから対応する作品のリンクを選択し、検索を行ってください。<br/>
						各タイトルの略称は以下の通りです。
						<ul>
							<li>HC: ハニカム</li>
							<li>KKS: コイカツ！サンシャイン</li>
							<li style={styleDisabled}>RG: ルームガール</li>
							<li style={styleDisabled}>KK: コイカツ！</li>
							<li style={styleDisabled}>AI: AI少女/ハニーセレクト2</li>
						</ul>
						検索画面のカード画像をマウスオーバーするとダウンロードアイコンが表示され、クリックすることでキャラクターカードファイルをダウンロードすることができます。
					</p>
					<h5>アップロード</h5>
					<p>
						ユーザー登録が完了し、ログインすることでアップロードができるようになります。<br/>
						アップロードする際に、キャラクターカードの公開/非公開を選択することができます。<br/>
						カード説明を記入してから公開したい時は非公開を選択してアップロードしてください。<br/>
						公開/非公開の状態に関わらず、カード説明の更新はマイページからいつでも自由に行うことができます。<br/>
						尚、同じファイルをアップロードすると差し替えになります。詳しい仕様は事項を参照してください。
					</p>
					<h5>ファイルの差し替えについて</h5>
					<p>
						キャラクターカードには内部的に「データID」というものが記録されており、キャラクターエディット画面で「新規保存」を行うときに新しいデータIDが発行されるという仕様になっています。
						一方、上書き保存ではデータIDが発行されず、データIDは変化しません。
						<br/><br/>
						例）
						<ul>
							<li>データID「A」のカードをロードして同じカードへ上書き保存 ⇒ データIDは「A」のまま</li>
							<li>データID「A」のカードをロードして別名で新規保存 ⇒ データIDが発行され「B」になる</li>
							<li>データID「A」のカードをロードして他のカードに上書き保存 ⇒ 上書き先カードのデータIDが「A」になる</li>
						</ul>

						アップロードする際、データIDが同じファイルをアップロードすると同一のカードデータとして認識され、カード説明等はそのままにファイルだけ差し替えが行われます。
						<br/><br/>
						例）
						<ul>
							<li>新規カードを「すぐに公開する」でアップロード ⇒ 公開状態 / カード説明なし</li>
							<li>新規カードを「すぐに公開しない」でアップロード ⇒ 非公開状態 / カード説明なし</li>
							<li>同一データIDのファイルを「すぐに公開する」でアップロード<br/> ⇒ 既存カードの公開状態に関わらず公開状態 / カード説明を引継いで差し替え
							</li>
							<li>同一データIDのファイルを「すぐに公開しない」でアップロード<br/> ⇒ 既存カードの公開状態に関わらず非公開状態 / カード説明を引継いで差し替え
							</li>
						</ul>
					</p>
				</Container>
			</Container>
		</BasicLayout>
	);
}

export default Home;
