import React, {CSSProperties, useState} from 'react';
import {ImageEndpoint} from "../../Common/Module/ImageEndpoint";
import {typeArchiveCardItem} from "./TypeArchiveKksCardItem";
import {DownloadIcon} from "../../Common/Part/DownloadIcon";

type TypeProps = {
	item: typeArchiveCardItem,
}

const styleImage: CSSProperties = {
	width: 126,
	height: 176,
	margin: "auto",
};

const styleFrame: CSSProperties = {
	position: "relative",
	float: "left",
	margin: "3px",
	padding: 3,
	backgroundColor: "#ccc",
}

const styleDownloadIcon: CSSProperties = {
	display: "block",
	position: "absolute",
	right: 5,
	bottom: 5,
}

/**
 * Thumbnail 126x76
 * @param props
 * @constructor
 */
function ArchiveKksCardItem(props: TypeProps) {
	const [flagMouseOver, setFlagMouseOver] = useState<boolean>(false);
	const item: typeArchiveCardItem = props.item;
	const name: string = "(" + item.personality_name + ") " + item.name;

	const onMouseOver = () => {
		setFlagMouseOver(true);
	};

	const onMouseLeave = () => {
		setFlagMouseOver(false);
	}

	return (
		<div style={styleFrame} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
			<img alt={name} title={name} style={styleImage} src={ImageEndpoint.url(item.images.main_thumb)} />
			<DownloadIcon style={styleDownloadIcon} url={item.download_url} visible={flagMouseOver} />
		</div>
	);
}

export default ArchiveKksCardItem;
