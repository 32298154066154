import React, {CSSProperties} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";

const styleRegisterNav: CSSProperties = {
	fontSize: "0.8em",
	color: "#888",
}

function CreateNewAccount() {
	return (
		<BasicLayout>
			<Container className="mt-3">
				<h3>ログインしました</h3>
			</Container>
			<Container style={styleRegisterNav} className="mt-2 mb-2">
				アップロードができるようになりました
			</Container>
			<Container style={styleRegisterNav} className="mt-2 mb-2">
				<Link className="link-primary" to={"/upload"}>アップロードする</Link>
			</Container>
			<Container style={styleRegisterNav} className="mt-2 mb-2">
				<Link className="link-primary" to={"/"}>ホームへ戻る</Link>
			</Container>
		</BasicLayout>
	);
}

export default CreateNewAccount;
