import React, {CSSProperties, useRef, useState} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {Button, Container, Form} from "react-bootstrap";
import {InputValidator, typeInputErrors, ValidationTip} from "../Common/Module/InputErrorChecker";
import {ServerApi} from "../Common/Module/ServerApi";
import {useNavigate} from "react-router";

const styleForm: CSSProperties = {
	width: "400px",
	textAlign: "left",
};

const styleTips: CSSProperties = {
	fontSize: "0.8em",
	color: "#888",
}

const styleErrorMessage: CSSProperties = {
	color: "#f00",
}

const formValidations = {
	loginId: {
		lengthMin: 3,
		lengthMax: 30,
		format: "^[a-zA-Z0-9_-]*$",
	},
	password: {
		lengthMin: 15,
	}
};

function CreateNewAccount() {
	const navigate = useNavigate();
	const [flagInProgress, setFlagInProgress] = useState<boolean>(false);
	const refLoginId = useRef<HTMLInputElement>(null);
	const refPassword = useRef<HTMLInputElement>(null);
	const refDisplayName = useRef<HTMLInputElement>(null);
	const validator = new InputValidator(formValidations);
	const [displayError, setDisplayError] = useState<string>("");

	const [errorsLoginId, setErrorsLoginId] = useState<typeInputErrors>([]);
	const [errorsPassword, setErrorsPassword] = useState<typeInputErrors>([]);

	const checkLoginId = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setErrorsLoginId(validator.validate("loginId", e.target.value, false));
	}

	const checkPassword = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setErrorsPassword(validator.validate("password", e.target.value, false));
	}

	const onSubmit = () => {
		setFlagInProgress(true);
		setDisplayError("");
		ServerApi.post("/api/account/register", {
			login_id: refLoginId.current?.value,
			password: refPassword.current?.value,
			display_name: refDisplayName.current?.value,
		}).then(() => {
			setFlagInProgress(false);
			setDisplayError("");
			navigate("/account/created");
		}).catch(function (result) {
			const data = result.response.data;
			setDisplayError(data.error);
			setFlagInProgress(false);
		});
	}

	return (
		<BasicLayout>
			<Container className="mt-3">
				<h3>新規ユーザー登録</h3>
			</Container>
			<Container style={styleForm}>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>ログインID</Form.Label>
						<Form.Control
							type="text"
							placeholder="Login ID"
							ref={refLoginId}
							onChange={checkLoginId}
							readOnly={flagInProgress}
							autoComplete="username"
						/>
						<div style={styleTips}>
							<ValidationTip errorStatus={errorsLoginId} errorId="lengthMin">{formValidations.loginId.lengthMin}文字以上</ValidationTip>,
							<ValidationTip errorStatus={errorsLoginId} errorId="lengthMax">{formValidations.loginId.lengthMax}文字以内</ValidationTip>
						</div>
						<div style={styleTips}>
							<ValidationTip errorStatus={errorsLoginId} errorId="format">使用可能文字: 半角英数字, ハイフン, アンダースコア</ValidationTip>,
						</div>
						<div style={styleTips}>
							他のユーザーと重複することはできません。他のユーザーには公開されません。
						</div>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>パスワード</Form.Label>
						<Form.Control
							type="password"
							ref={refPassword}
							placeholder="Password"
							onChange={checkPassword}
							readOnly={flagInProgress}
							autoComplete="new-password"
						/>
						<div style={styleTips}>
							<ValidationTip errorStatus={errorsPassword} errorId="lengthMin">{formValidations.password.lengthMin}文字以上</ValidationTip>
						</div>
						<div style={styleTips}>
							パスワード生成ツールを利用してランダムな文字列を生成してください。
						</div>
						<div style={styleTips}>
							ブラウザのパスワードマネージャー機能を使って保存して下さい。
						</div>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>ユーザー名</Form.Label>
						<Form.Control type="text" placeholder="Display name" ref={refDisplayName} readOnly={flagInProgress} />
						<div style={styleTips}>
							任意入力。他のユーザーに公開されます。
						</div>
					</Form.Group>
					<Form.Group className="mb-3 text-end">
						<span className="p-2" style={styleErrorMessage}>{displayError}</span>
						<Button variant="primary" onClick={onSubmit} disabled={flagInProgress}>登録</Button>
					</Form.Group>
				</Form>
			</Container>
		</BasicLayout>
	);
}

export default CreateNewAccount;
