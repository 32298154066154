import React, {CSSProperties} from 'react';
import "./GlobalHeader.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {UserStateContext} from "../Context/UserStateContext";

const NavLoggedIn = function () {
	return (
		<>
			<Nav.Link as={NavLink} to={"/mypage"}>Mypage</Nav.Link>
			<Nav.Link as={NavLink} to={"/account/logout"}>Logout</Nav.Link>
		</>
	);
}

const NavLoggedOut = function () {
	return (
		<>
			<Nav.Link as={NavLink} to={"/account/login"}>Login</Nav.Link>
		</>
	);
}

const styleBorder: CSSProperties = {
	borderLeft: "solid 1px #ddd",
	marginTop: 3,
	marginBottom: 3,
	marginRight: 3,
}

function GlobalHeader() {
	const {state} = React.useContext(UserStateContext);
	const isSandbox: boolean = process.env.REACT_APP_SANDBOX_MODE?.toString() === "1";
	let user_nav = null;

	if (state.initialized) {
		if (state.logged_in) {
			user_nav = NavLoggedIn();
		} else {
			user_nav = NavLoggedOut();
		}
	}

	return (
		<Navbar className="bg-body-tertiary">
			<Container>
				<Navbar.Brand as={NavLink} to="/">Uploader</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav"/>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Item style={styleBorder}/>
						<Nav.Link as={NavLink} to="/cards/hc">HC</Nav.Link>
						<Nav.Link as={NavLink} to="/cards/kks">KKS</Nav.Link>
						{
							isSandbox ?
								<Nav.Link as={NavLink} to="/archive/kks">
									KKS(Archive)
								</Nav.Link> : undefined
						}
						<Nav.Item style={styleBorder}/>
						<Nav.Link as={NavLink} to={"/upload"}>Upload</Nav.Link>
						{user_nav}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default GlobalHeader;
