import Accordion from 'react-bootstrap/Accordion';
import {Button} from "react-bootstrap";
import {useState} from "react";

const style = {
	backgroundColor: "#eee",
};

const styleButton = {
	fontSize: "0.8em",
	padding: "3px",
	margin: "2px",
};

type typePersonalityItem = [number, string, string];

const constPersonalities: typePersonalityItem[] = [
	[-2, "指定なし", "All"],
	[-1, "男", "Male"],
	[0, "セクシー", "Flirt"],
	[1, "お嬢様", "Heiress"],
	[2, "高飛車", "Snobby"],
	[3, "後輩キャラ", "Kouhai"],
	[4, "ミステリアス", "Mysterious"],
	[5, "電波", "Weirdo"],
	[6, "大和撫子", "YamatoNadeshiko"],
	[7, "ボーイッシュ", "Boyish"],
	[8, "純真無垢", "Pure"],
	[9, "単純", "Brunt"],
	[10, "邪気眼", "EvilEye"],
	[11, "母性的", "Motherly"],
	[12, "姉御肌", "BigSisterly"],
	[13, "ギャル", "Gyaru"],
	[14, "不良少女", "BadGirl"],
	[15, "野性的", "Wild"],
	[16, "意識高いクール", "Wannabe"],
	[17, "ひねくれ", "Crabby"],
	[18, "不幸少女", "UnluckyGirl"],
	[19, "文学少女", "BookishGirl"],
	[20, "モジモジ", "Nervous"],
	[21, "正統派ヒロイン", "TypicalHeroine"],
	[22, "ミーハー", "Trendy"],
	[23, "オタク女子", "OtakuGirl"],
	[24, "ヤンデレ", "Yandere"],
	[25, "ものぐさ", "Lazy"],
	[26, "無口", "Quiet"],
	[27, "意地っ張り", "Stubborn"],
	[28, "のじゃっ子", "OldFashioned"],
	[29, "素直クール", "Docile"],
	[30, "気さく", "Friendly"],
	[31, "勝ち気", "Willful"],
	[32, "誠実", "Honest"],
	[33, "艶やか", "Charming"],
	[34, "帰国子女", "Returnee"],
	[35, "方言娘", "Slangy"],
	[36, "Ｓッ気", "Sadistic"],
	[37, "無感情", "Emotionless"],
	[38, "几帳面", "Careful"],
	[39, "島っ娘", "Islanders"],
	[40, "高潔", "Integrity"],
	[41, "ボクっ娘", "Bokukko"],
	[42, "天真爛漫", "Guiltless"],
	[43, "ノリノリ", "Comedian"],
];

type props = {
	onChanged: (sex: string , personality: string) => void,
};

function SearchMenuArchiveKks(props: props) {
	const [selectedPersonality, setSelectedPersonality] = useState(-2);

	const onClickPersonality = async (id: number) => {
		setSelectedPersonality(id);
		const sex = (id === -2) ? "" : (id === -1 ? "0" : "1");
		const personality = (id === -2 || id === -1) ? "" : id.toString();
		props.onChanged(sex, personality);
	};

	return (
		<Accordion>
			<Accordion.Item eventKey="0">
				<Accordion.Header>検索条件</Accordion.Header>
				<Accordion.Body>
					{
						constPersonalities.map((item) => {
							const id = item[0];
							const name = item[1];
							return (
								<Button key={id} variant={id === selectedPersonality ? "primary" : "secondary"} style={styleButton} onClick={() => {
									onClickPersonality(id)
								}}>{name}</Button>
							);
						})
					}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}

export default SearchMenuArchiveKks;
