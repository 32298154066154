import styled from "styled-components";
import {CheckMark} from "../Common/Part/CheckMark";
import CloseButton from "../Common/Part/CloseButton";
import {typeUploadQueueItem} from "./TypesUploader";
import {CSSProperties, useState} from "react";
import {Spinner} from "react-bootstrap";

const styleCardFrame: CSSProperties = {
	float: "left",
	margin: "3px",
}

const styleCardInnerFrame: CSSProperties = {
	position: "relative",
}

const styleCardImage: CSSProperties = {
	width: "126px",
	height: "176px",
	zIndex: 10,
}

const UploadStatus = styled.div`
	display: block;
	position: absolute;
	bottom: 3px;
	right: 3px;
	width: 32px;
	height: 32px;
`

const UpdatedMark = styled.div`
  display: block;
  position: absolute;
  bottom: 3px;
  right: 30px;
  font-size: 1em;
  font-weight: bold;
  text-shadow: 1px 0 0 #ccc,
  1px 1px 0 #ccc,
  0 1px 0 #ccc,
  -1px 1px 0 #fff,
  -1px 0 0 #fff,
    -1px -1px 0 #fff,
    0 -1px 0 #fff,
    1px -1px 0 #fff;
`

/**
 * アップロードキュー カード画像
 * @param props
 * @constructor
 */
export const UploadQueueItemCard = (props: { item: typeUploadQueueItem, onRemove: () => void }) => {
	const item: typeUploadQueueItem = props.item;
	const [visibleCloseButton, setVisibleCloseButton] = useState<boolean>(false);
	return (
		<div style={styleCardFrame} onMouseOver={() => {
			setVisibleCloseButton(true)
		}} onMouseLeave={() => {
			setVisibleCloseButton(false)
		}}>
			<div style={styleCardInnerFrame}>
				<img style={styleCardImage} src={item.image} alt={item.file_name} title={item.file_name} onMouseDown={(event) => {
					event.preventDefault();
				}} />
				<CloseButton onClick={props.onRemove} style={{visibility: (visibleCloseButton && item.status === "waiting") ? "visible" : "hidden"}} />
				<UploadStatus>
					<Spinner animation="border" variant="light" role="status" style={{
						opacity: "80%",
						visibility: item.status === "uploading" ? "visible" : "hidden"
					}} />
				</UploadStatus>
				<CheckMark style={{
					visibility: item.status === "uploaded" ? "visible" : "hidden"
				}} />
				<UpdatedMark style={{
					visibility: item.replaced ? "visible" : "hidden"
				}}>UPDATED</UpdatedMark>
			</div>
		</div>
	);
}
