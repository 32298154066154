import React, {CSSProperties, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import BasicLayout from "../Common/Layout/BasicLayout";
import {ServerApi} from "../Common/Module/ServerApi";
import {Alert, Button, Container, Form, Spinner} from "react-bootstrap";
import {UserStateContext} from "../Common/Context/UserStateContext";
import {Link} from "react-router-dom";
import {UploadQueueItemCard} from "./UploadQueueItemCard";
import {typeUploadQueueItem} from "./TypesUploader";

const styleCommon: CSSProperties = {
	width: "auto",
	margin: "10px 10px",
	minHeight: "200px",
	border: "1px dotted #888",
	padding: "8px",
};

const styleWaiting: CSSProperties = {
	...styleCommon,
	backgroundColor: "#eee",
};

const styleOnDropping: CSSProperties = {
	...styleCommon,
	backgroundColor: "#ffd",
};

let lastQueueItemIndex: number = 0;

function loadImage(file: File): Promise<string> {
	return new Promise(res => {
		const reader = new FileReader();
		reader.onload = function (e: ProgressEvent) {
			const read_result: FileReader = e.target as FileReader;
			res(read_result.result as string);
		}
		reader.readAsDataURL(file);
	})
}

/**
 * ドロップゾーン
 * @constructor
 */
const DropAreaMessage = () => {
	const styleItem = {
		fontSize: "0.9em",
	}

	return (
		<div>
			<div>
				アップロードするファイルをここにドラッグ＆ドロップしてください
			</div>
			<div style={{fontSize: "1.0em", fontWeight: "bold"}}>アップロード可能なファイル形式</div>
			<div style={{textAlign: "left", width: 180, margin: "0 auto"}}>
				<div style={styleItem}>[OK] ハニカム</div>
				<div style={styleItem}>[OK] コイカツ！サンシャイン</div>
				<div style={styleItem}>[NG] コイカツ！</div>
				<div style={styleItem}>[NG] ルームガール</div>
				<div style={styleItem}>[NG] AI少女/ハニーセレクト2</div>
			</div>
		</div>
	);
}

/**
 * メイン
 * @constructor
 */
function PageCardUploader() {
	const loginState = React.useContext(UserStateContext);
	const [uploadingStateText, setUploadingStateText] = useState<string>("");
	const [flagUploading, setFlagUploading] = useState<boolean>(false);
	const [flagFinished, setFlagFinished] = useState<boolean>(false);
	const [uploadItemList, setUploadItemList] = useState<Array<typeUploadQueueItem>>([]);
	const [flagPublish, setFlagPublish] = useState<boolean>(true);

	// UI参照
	const refStartUploadButton = useRef<HTMLButtonElement>(null);
	const refFileSelectButton = useRef<HTMLButtonElement>(null);
	const refFileSelector = useRef<HTMLInputElement>(null);
	const refSwitchPublish = useRef<HTMLInputElement>(null);

	const checkLoginStatus = (): boolean => {
		if (!loginState.state.logged_in) {
			alert("ファイルのアップロードにはログインが必要です");
			return false;
		}
		return true;
	}

	const checkDuplicate = (list: Array<typeUploadQueueItem>, file: File): boolean => {
		for (let i in list) {
			const item = list[i];
			if (item.file_name === file.name) {
				return true;
			}
		}
		return false;
	};

	const removeUploadItem = (uploadingItems: Array<typeUploadQueueItem>, target: number) => {
		const newItems: Array<typeUploadQueueItem> = [];
		for (let i in uploadingItems) {
			const item = uploadingItems[i];
			if (item.key !== target) {
				newItems.push(item);
			}
		}
		return newItems;
	}

	// Drag and drop
	const onDrop = async (acceptedFiles: any) => {
		const newItems = [...uploadItemList];
		for (let i in acceptedFiles) {
			const file: File = acceptedFiles[i];
			if (!checkDuplicate(newItems, file)) {
				const image = await loadImage(file);
				const newItem: typeUploadQueueItem = {
					key: lastQueueItemIndex++,
					file_name: file.name,
					file_type: file.type,
					status: "waiting",
					file: file,
					image: image,
					product_id: null,
					character_name: null,
					replaced: null,
				};
				newItems.push(newItem);
			}
		}
		setUploadItemList(newItems);
	};

	/**
	 * 次のアップロード対象をピックアップする
	 * @param uploadItems
	 */
	const pickNextUploadItem = (uploadItems: Array<typeUploadQueueItem>): typeUploadQueueItem | null => {
		let uploadItem: typeUploadQueueItem | null = null;
		for (let i in uploadItems) {
			const target: typeUploadQueueItem = uploadItems[i];
			if (target.status === "waiting") {
				uploadItem = target;
				uploadItem.status = "uploading";
				break;
			}
		}
		return uploadItem;
	}

	/**
	 * HTML要素を無効化する
	 */
	const disableInputElements = (refs: Array<React.RefObject<HTMLInputElement | HTMLButtonElement>>) => {
		for (let i in refs) {
			const elm = refs[i].current;
			if (elm !== null) {
				elm.disabled = true;
			}
		}
	};

	/**
	 * アップロードステータス文字列の構築
	 * @param uploadItems
	 */
	const buildUploadingStatusText = (uploadItems: Array<typeUploadQueueItem>): string => {
		let total = 0;
		let uploaded = 0;
		let replaced = 0;
		let failed = 0;
		for (let i in uploadItems) {
			const item = uploadItems[i];
			if (item.status === "uploaded") {
				uploaded++;
				replaced += item.replaced ? 1 : 0;
			} else if (item.status === "failed") {
				failed++;
			}
			total++;
		}

		return "完了:" + uploaded + "件 / 全体:" + total + "件" +
			(replaced > 0 ? (" / 置換:" + replaced + "件") : "") +
			(failed > 0 ? (" / 失敗:" + failed + "件") : "");
	}

	/**
	 * アップロードの開始
	 */
	const startUpload = () => {
		// ログイン状態チェック
		if (!checkLoginStatus()) {
			return;
		}

		// 件数チェック
		if (uploadItemList.length === 0) {
			return;
		}

		// アップロード開始済ならば無効
		if (flagUploading) {
			return;
		}

		// アップロード開始ボタン無効化
		disableInputElements([
			refStartUploadButton,
			refFileSelectButton,
			refSwitchPublish
		]);

		// アップロード状態にする
		setFlagUploading(true);

		// 公開フラグを取得
		const actualFlagPublish: boolean = refSwitchPublish.current?.checked ?? false;

		// アップロード開始
		progressUpload(actualFlagPublish);
	}

	/**
	 * アップロード1件の処理
	 */
	const progressUpload = (actualFlagPublish: boolean) => {
		// 状態更新
		setUploadingStateText(buildUploadingStatusText(uploadItemList));

		// 次のアップロード対象をピックアップする
		const uploadItem: typeUploadQueueItem | null = pickNextUploadItem(uploadItemList);

		// 全てのアップロードが完了したら完了
		if (uploadItem === null) {
			setFlagUploading(false);
			setFlagFinished(true);
			return;
		}

		// ステータスを更新
		uploadItem.status = "uploading";
		setUploadItemList([...uploadItemList]);

		// アップロード開始
		ServerApi.upload("/api/upload/new", {
			index: uploadItem.key.toString(),
			publish: actualFlagPublish ? "1" : "0",
		}, {
			file: uploadItem.file,
		}).then((res) => {
			if (res.accepted) {
				uploadItem.status = "uploaded";
				uploadItem.replaced = res.replaced;
				uploadItem.product_id = res.product_id;
				uploadItem.character_name = res.character_name;
			} else {
				uploadItem.status = "failed";
			}
			setUploadItemList([...uploadItemList]);

			// 次の回へ
			progressUpload(actualFlagPublish);
		});
	}

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

	return (
		<BasicLayout>
			<Container>
				{
					loginState.state.logged_in ? undefined :
						<Alert variant="danger" className="mt-3">
							ファイルのアップロードをするには<Link to={"/account/login"} className="link-primary">ログイン</Link>してください
						</Alert>
				}
			</Container>
			<Container>
				<div {...getRootProps()} style={isDragActive ? styleOnDropping : styleWaiting}>
					{
						uploadItemList.map((item) => {
							return <UploadQueueItemCard key={item.key} item={item} onRemove={() => {
								setUploadItemList(removeUploadItem(uploadItemList, item.key));
							}} />
						})
					}
					{
						uploadItemList.length === 0 ? <DropAreaMessage /> : <div className="clearfix" />
					}
				</div>
			</Container>
			<Container style={{textAlign: "right"}}>
				<input {...getInputProps()} ref={refFileSelector} />
				<Button ref={refFileSelectButton} style={{fontSize: "0.8em", marginRight: "8px"}} onClick={() => {
					const selector = refFileSelector.current;
					if (selector) {
						if (!checkLoginStatus()) {
							return;
						}
						selector.showPicker();
					}
				}}>ファイルを選択する</Button>
			</Container>
			<Container style={{width: 600, textAlign: "left"}}>
				<Form>
					<Container style={{textAlign: "left"}}>
						<Form.Check type="switch" ref={refSwitchPublish} label="すぐに公開する" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFlagPublish(e.target.checked);
						}} checked={flagPublish} />
						<div style={{height: "100px"}}>
							{flagPublish ?
								<p>
									アップロード完了後、直ちに公開されます。<br />
									カード説明はマイページからいつでも変更することができます。<br />
									アップロード済みカードにデータIDが同一のものがあれば自動的に差し替えられます。<br />
								</p> :
								<p>
									アップロード完了後、非公開状態で保存されます。 <br />
									マイページでカード説明を記入してから公開することができます。<br />
									アップロード済みカードにデータIDが同一のものがあれば自動的に差し替えられます。<br />
								</p>
							}
						</div>
					</Container>
					<Container style={{textAlign: "right"}}>
						<span style={{fontSize: "1.0em", marginRight: 8}}>{uploadingStateText}</span>
						<Spinner animation="border" variant="primary" role="status" size="sm" style={{
							width: 15,
							height: 15,
							marginRight: 12,
							opacity: "90%",
							visibility: flagUploading ? "visible" : "hidden",
						}} />
						<Button ref={refStartUploadButton} onClick={startUpload}>アップロード</Button>
					</Container>
					{
						flagFinished ?
							<Container className="mt-3" style={{textAlign: "right"}}>
								アップロードが完了しました
								<Link className="link-primary" to={"/mypage"} style={{marginLeft: 12}}>マイページへ</Link>
							</Container> : <></>
					}
				</Form>
			</Container>
			<Container className="mt-5"></Container>
		</BasicLayout>
	);
}

export default PageCardUploader;
