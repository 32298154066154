import React, {CSSProperties} from "react";

export const CheckMark = (props: { style: CSSProperties }) => {
	const styleElement: CSSProperties = {
		display: "block",
		position: "absolute",
		bottom: 3,
		right: 3,
		width: 32,
		height: 32,
		color: "#fff",
		backgroundColor: "#4d4",
		borderRadius: "50%",
		border: "solid 2px #afa",
		opacity: "90%",
		...props.style
	};

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16" style={styleElement}>
			<path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
		</svg>
	)
}

export default CheckMark;
