import React, {CSSProperties, useRef, useState} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {Button, Container, Form} from "react-bootstrap";
import {UserStateContext} from "../Common/Context/UserStateContext";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {useNavigate} from "react-router";
import {ServerApi} from "../Common/Module/ServerApi";

const styleForm: CSSProperties = {
	width: "400px",
	textAlign: "left",
};

const styleRegisterNav: CSSProperties = {
	fontSize: "0.8em",
	color: "#888",
}

const styleErrorMessage: CSSProperties = {
	color: "#f00",
}

const guideRegister =
	<Container style={styleRegisterNav} className="mt-2 mb-2">
		アカウントをまだお持ちでない方 ⇒ <Link className="link-primary" to={"/account/register"}>ユーザー登録</Link>
	</Container>

const guideLoggedOut =
	<Container style={styleRegisterNav} className="mt-2 mb-2">
		ログアウトしました
	</Container>


function Login() {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(useLocation().search);
	const modeCreated = queryParams.get("created") === "1";
	const modeLoggedOut = queryParams.get("logout") === "1";
	const [flagInProgress, setFlagInProgress] = useState<boolean>(false);
	const {state, dispatch} = React.useContext(UserStateContext);
	const refLoginId = useRef<HTMLInputElement>(null);
	const refPassword = useRef<HTMLInputElement>(null);
	const [displayError, setDisplayError] = useState<string>("");

	const onClickLogin = async () => {
		setFlagInProgress(true);
		setDisplayError("");
		ServerApi.post("/api/account/authenticate", {
			login_id: refLoginId.current?.value,
			password: refPassword.current?.value,
		}).then((data) => {
			setFlagInProgress(false);
			setDisplayError("");
			dispatch({action: "set", user_id: data.user_id, display_name: data.display_name});
			navigate("/account/logged_in");
		}).catch(function (result) {
			const data = result.response.data;
			setDisplayError(data.error);
			setFlagInProgress(false);
		});
	}

	return (
		<BasicLayout>
			<Container className="mt-3">
				<h3>ログイン</h3>
			</Container>
			<Container style={styleForm}>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>ログインID</Form.Label>
						<Form.Control type="text" placeholder="Login ID" ref={refLoginId} readOnly={flagInProgress} autoComplete="username" />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>パスワード</Form.Label>
						<Form.Control type="password" ref={refPassword} placeholder="Password" readOnly={flagInProgress} autoComplete="password" />
					</Form.Group>
					<Form.Group className="mb-3 text-end">
						<span className="p-2" style={styleErrorMessage}>{displayError}</span>
						<Button variant="primary" onClick={onClickLogin} disabled={flagInProgress}>認証</Button>
					</Form.Group>
				</Form>
			</Container>
			<Container className="mt-3">
				{modeCreated ? <></> : (modeLoggedOut ? guideLoggedOut : guideRegister)}
			</Container>
		</BasicLayout>
	);
}

export default Login;
