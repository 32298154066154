import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import BasicLayout from "../Common/Layout/BasicLayout";
import {useParams} from "react-router-dom";
import {ServerApi} from "../Common/Module/ServerApi";
import {typeCardDetail, typeCardUpdateResult} from "./TypeMypage";
import {ImageEndpoint} from "../Common/Module/ImageEndpoint";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import {useForm} from "react-hook-form";
import Accordion from "react-bootstrap/Accordion";
import {useNavigate} from "react-router";

const styleImage: CSSProperties = {
	cursor: "pointer",
	margin: "auto",
}

const paddingImage = 6;
const styleImageFrame: CSSProperties = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: 252 + paddingImage * 2,
	height: 352 + paddingImage * 2,
	padding: paddingImage,
	margin: 8,
	backgroundColor: "#ddd",
	borderRadius: "6px",
}

const stylePublishSwitch: CSSProperties = {
	margin: "23px 30px 0 0",
}

type typeFormInput = {
	description: string,
	tags: string,
	is_public: boolean,
}

type typeModalProps = {
	show: boolean,
	onClickOK: () => void,
}

/**
 * 保存結果表示モーダル
 * @param props
 * @constructor
 */
const ConfirmModal = (props: typeModalProps) => {
	return (
		<Modal show={props.show}>
			<Modal.Header closeButton style={{border: "none"}} onClick={props.onClickOK}>
				<Modal.Title></Modal.Title>
			</Modal.Header>
			<Modal.Body className="text-center">カード情報を保存しました</Modal.Body>
			<Modal.Footer style={{border: "none"}}>
				<Button variant="primary" onClick={props.onClickOK}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

type typeDeleteModalProps = {
	show: boolean,
	onClickCancel: () => void,
	onClickDelete: () => void,
	refDelete: React.Ref<HTMLButtonElement>,
}

/**
 * 削除モーダル
 * @param props
 * @constructor
 */
const DeleteModal = (props: typeDeleteModalProps) => {
	return (
		<Modal show={props.show}>
			<Modal.Header closeButton style={{border: "none"}} onClick={props.onClickCancel}>
				<Modal.Title></Modal.Title>
			</Modal.Header>
			<Modal.Body className="text-center">カードを削除します。よろしいですか？</Modal.Body>
			<Modal.Footer style={{border: "none"}}>
				<Button variant="secondary" onClick={props.onClickCancel}>
					キャンセル
				</Button>
				<Button variant="danger" onClick={props.onClickDelete} ref={props.refDelete}>
					削除
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

/**
 * カード編集
 * @constructor
 */
function PageMypageCardEdit() {
	const navigate = useNavigate();
	const {own_id} = useParams<string>();
	const [card, setCard] = useState<typeCardDetail | null>(null);
	const [flagFlip, setFlagFlip] = useState<boolean>(false);
	const refSubmit = useRef<HTMLButtonElement>(null);
	const refDelete = useRef<HTMLButtonElement>(null);
	const {register, setValue, handleSubmit} = useForm<typeFormInput>();
	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleCloseDeleteModal = () => setShowDeleteModal(false);
	const handleShowDeleteModal = () => setShowDeleteModal(true);

	const flipMainImage = () => {
		setFlagFlip(false);
	}

	const flipSubImage = () => {
		setFlagFlip(true);
	}

	useEffect(() => {
		ServerApi.get("/api/mypage/card/get", {
			own_id: (own_id ?? "").toString(),
		}).then((response) => {
			const data: typeCardDetail = response as typeCardDetail;
			setCard(data);
			setValue("is_public", data.is_public);
			setValue("description", data.description ?? "");
			setValue("tags", data.tags ?? "");
		});
	}, [own_id, setValue]);

	if (card === null) {
		return (
			<BasicLayout>
				<Spinner style={{width: 50, height: 50, margin: 50, opacity: "50%"}} />
			</BasicLayout>
		);
	}

	/**
	 * 更新処理
	 * @param data
	 */
	const onSubmit = (data: typeFormInput) => {
		if (refSubmit.current) {
			refSubmit.current.disabled = true;
		}
		ServerApi.post("/api/mypage/card/update", {
			own_id: (own_id ?? "").toString(),
			is_public: data.is_public ? "1" : "0",
			description: data.description,
			tags: data.tags,
		}).then((response) => {
			const data: typeCardUpdateResult = response as typeCardUpdateResult;
			setValue("is_public", data.is_public);
			setValue("description", data.description ?? "");
			setValue("tags", data.tags ?? "");
			if (refSubmit.current) {
				refSubmit.current.disabled = false;
			}
		});
		handleShowModal();
	}

	const executeDelete = () => {
		if (refDelete.current) {
			refDelete.current.disabled = true;
		}
		ServerApi.post("/api/mypage/card/delete", {
			own_id: (own_id ?? "").toString(),
		}).then(() => {
			navigate("/mypage");
		});
	};

	return (
		<BasicLayout>
			<Container style={{width: 800}} className="mb-5">
				<Row>
					<Col style={{backgroundColor: "#fff"}}>
						<div style={styleImageFrame}>
							{flagFlip ?
								<img style={styleImage} src={ImageEndpoint.url(card.images.sub)} alt={"サブ画像"} onClick={flipMainImage} /> :
								<img style={styleImage} src={ImageEndpoint.url(card.images.main)} alt={"メイン画像"} onClick={flipSubImage} />}
						</div>
						<div className="clearfix" />
					</Col>
					<Col style={{backgroundColor: "#fff", textAlign: "left"}}>
						<dl>
							<dt>ファイルフォーマット</dt>
							<dd>{card.product.name}</dd>
						</dl>
						<dl>
							<dt>キャラクター名</dt>
							<dd>{card.card_params.name}</dd>
						</dl>
						<dl>
							<dt>性格</dt>
							<dd>{card.card_params.personality_name}</dd>
						</dl>
					</Col>
				</Row>
				<Row>
					<Col style={{backgroundColor: "#eee", textAlign: "left", borderRadius: "5px", padding: "6px 12px"}}>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group className="mb-3">
								<Form.Label>カード説明</Form.Label>
								<Form.Control as="textarea"  {...register('description')} style={{minHeight: 100}} />
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>タグ</Form.Label>
								<Form.Control as="textarea"  {...register('tags')} />
							</Form.Group>
							<div className="d-flex justify-content-end">
								<Form.Group className="mb-3" style={stylePublishSwitch}>
									<Form.Check type="switch" label="公開する" {...register('is_public')} />
								</Form.Group>
								<Form.Group className="mt-3 mb-3">
									<Button variant="primary" type="submit" ref={refSubmit}>更新</Button>
								</Form.Group>
							</div>
						</Form>
					</Col>
				</Row>
				<Row className="mt-5">
					<Col style={{backgroundColor: "#eee", textAlign: "left", borderRadius: "5px", padding: "6px 12px"}}>
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>その他のコマンド</Accordion.Header>
								<Accordion.Body>
									<Button variant="danger" type="submit" onClick={handleShowDeleteModal}>削除</Button>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
			</Container>
			<ConfirmModal show={showModal} onClickOK={handleCloseModal} />
			<DeleteModal show={showDeleteModal} onClickCancel={handleCloseDeleteModal} onClickDelete={executeDelete} refDelete={refDelete} />
		</BasicLayout>
	);
}

export default PageMypageCardEdit;
